/* PORTFOLIO START */
#portfolio {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}

@media (min-width: 600px) {
  #portfolio {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}

@media (min-width: 1000px) {
  #portfolio {
    grid-template-columns: repeat(3, 1fr);
  }
  .portfolio-item {
    height: 500px;
  }
}

@media (min-width: 1400px) {
  #portfolio {
    grid-template-columns: repeat(4, 1fr);
  }
  .portfolio-item {
    height: 600px;
  }
}

#portfolio a:hover {
  padding-bottom: 0;
  border: none;
}

.portfolio-item {
  width: 100%;
  height: 400px;
  opacity: 0.9;
  cursor: pointer;

  border-radius: 2px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.portfolio-item:hover {
  opacity: 1;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
}

.portfolio-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
}

/* PORTFOLIO END */
