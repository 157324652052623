html {
  max-width: 100vw;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  padding: 10px;
  overflow-x: hidden;
  max-width: 100vw;
  background: #f9fbfc;
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  /* font-size: large; */
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 1000px) {
  body {
    font-size: large;
    padding: 0;
    padding: 20px;
  }
}

#root {
  position: relative;
  min-height: 95vh;
}

@media (min-width: 800px) {
  #root {
    position: static;
  }
}

a,
a:hover,
a:visited {
  text-decoration: none !important;
  color: inherit !important;
  background: none !important;
  border: none !important;
  cursor: pointer !important;
}

a:hover {
  padding-bottom: 2px !important;
  border-bottom: 2px solid !important;
}

/* EDITOR */
input,
textarea {
  color: inherit;
  background: inherit;
  border: none;
  font: inherit;
}

input:focus,
textarea:focus {
  outline-color: darkblue;
  /* outline-style: dashed;
  outline-width: 1px; */
}
